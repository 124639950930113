@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Italianno&family=Corinthia&family=Ephesis&family=Splash&family=Pattaya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');


.font-italianno-regular {
    font-family: "Italianno", cursive;
    /* font-weight: 400;
    font-style: normal; */
}

.font-arizonia-regular {
    font-family: "Arizonia", cursive;
    /* font-weight: 400;
    font-style: normal; */
}

.font-corinthia-regular {
    font-family: "Corinthia", cursive;
    /* font-weight: 400;
    font-style: normal; */
}

.font-ephesis-regular {
    font-family: "Ephesis", cursive;
    font-weight: 100;
    /* font-style: normal; */
}

.font-splash-regular {
    font-family: "Splash", cursive;
    /* font-weight: 400;
    font-style: normal; */
}

.font-tourney-custom {
    font-family: "Tourney", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
}

.font-pattaya-regular {
    font-family: "Pattaya", sans-serif;
    /* font-weight: 400;
    font-style: normal; */
}

.text-white-stroke {
    -webkit-text-stroke: 0.7px #ffffff;
}