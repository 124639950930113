@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&family=Pacifico&family=The+Nautigal:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300&family=The+Nautigal:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Raleway:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

@layer components {
    .Momo {
        @apply text-[#a50064] font-bold text-xl leading-3;
    }
    .TPBank {
        @apply text-[#5e2e86] font-bold text-xl leading-3;
    }
    .VPBank {
        @apply text-[#00b74f] font-bold text-xl leading-3;
    }
    .ACB {
        @apply text-[#1348ab] font-bold text-xl leading-3;
    }
    .Agribank {
        @apply text-[#ae1c3f] font-bold text-xl leading-3;
    }
    .Vietcombank {
        @apply text-[#144633] font-bold text-[18px] leading-3;
    }
}

@layer base {
    * {
        font-family: 'Inter', sans-serif;
    }
    html {
        font-weight: normal;
        font-style: normal;
        color: 'black';
        overflow-x: hidden;
        scroll-behavior: smooth;
    }
    h1 {
        font-size: 48px;
        font-weight: bold;
    }

    h2 {
        font-size: 36px;
        font-weight: bold;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
    }

    h4 {
        font-size: 22px;
        font-weight: bold;
    }

    h5 {
        font-size: 18px;
        font-weight: bold;
    }

    /* p {
        font-size: 16px;
    } */
    ::-webkit-scrollbar {
        width: 10px;
        height: 20px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
    }
    ::-webkit-scrollbar-thumb {
        background: #e0cbcb;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #c0a0b9;
    }
}

.box-qr {
    --b: 2px;
    /* thickness of the border */
    --c: black;
    /* color of the border */
    --w: 20px;
    /* width of border */

    border: var(--b) solid transparent;
    /* space for the border */
    --g: #0000 90deg, var(--c) 0;
    background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g)) 0 0,
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100% 100%;
    background-size: var(--w) var(--w);
    background-origin: border-box;
    background-repeat: no-repeat;

    /*Irrelevant code*/
    /* width: 200px;
    height: 100px; */
    box-sizing: border-box;
    /* margin: 5px; */
    /* display: inline-flex; */
    /* font-size: 30px; */
    justify-content: center;
    align-items: center;
    /* line-height: 90px; */
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3b82f6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1d4ed8;
}
.sliderclass {
    display: flex !important;
}
.slick-track {
    display: flex !important;
}