/* remove border react-quill */
.ql-container {
    border: none !important;
}

.ql-toolbar {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}


#toolbarQuill{
    padding-top: 4px;
    padding-bottom: 4px;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Địa chỉ URL:" !important;
}
.ql-snow .ql-tooltip::before {
    content: "Xem URL:" !important;
}

