.slick-track {
    /* display: block !important; */
    /* gap: 0px !important; */
}
.slick-slide {
    margin-left: 10px;
    margin-right: 10px;
}
.flex {
    display: flex !important;
}
