@import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@300&display=swap');

h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 50px;
}

h2 {
    /* font-family: 'Lemonada', cursive; */
    font-size: 36px;
    font-weight: bold;
    line-height: 45px;
}

h3 {
    font-size: 28px;
    font-weight: bold;
    line-height: 30px;
}

h4 {
    font-size: 22px;
    font-weight: bold;
    line-height: 35px;
}

h5 {
    font-size: 18px;
    font-weight: bold;
}

/* p {
        font-size: 16px;
    } */

.text-normal-stroke {
    -webkit-text-stroke: 1.5px #3d4377;
}
.text-light-stroke {
    -webkit-text-stroke: 1px #3d4377;
}
.text-bold-stroke {
    -webkit-text-stroke: 2px #3d4377;
}
.text-blue-stroke {
    -webkit-text-stroke: 1.5px #3d4377;
}
.text-white-stroke {
    -webkit-text-stroke: 0.1px #ffffff;
}
.text-black-stroke {
    -webkit-text-stroke: 1px #0051ff;
}
