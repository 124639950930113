.slick-slider {
    height: 100% !important;
}

.slick-list {
    height: 100% !important;
}

.slick-prev {
    left: 0 !important;
    z-index: 1000 !important;
}

.slick-next {
    right: 0 !important;
    z-index: 1000 !important;
}